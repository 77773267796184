import { configureStore } from '@reduxjs/toolkit';

// general
import sidebarSlice from './sidebar/sidebarSlice';

// pages
import authSlice from "./auth/authSlice";

//header
import homeHeaderSlice from "./settings/home/homeHeaderSlice";
import homeOfferSlice from "./settings/home/homeOfferSlice";
import homeAboutSlice from "./settings/home/homeAboutSlice";
import homeAdviceSlice from "./settings/home/homeAdviceSlice";
import globalConfigSlice from "./settings/global/globalConfigSlice";
import globalDiscoverSlice from "./settings/global/globalDiscoverSlice";
import blogsSlice from "./blogs/blogsSlice";
import booksSlice from "./books/booksSlice";
import categoriesSlice from "./categories/categoriesSlice";
import aboutHeaderSlice from "./settings/about/aboutHeaderSlice";
import aboutAboutSlice from "./settings/about/aboutAboutSlice";
import slidersSlice from "./sliders/slidersSlice";
import booksHeaderSlice from "./settings/books/booksHeaderSlice";
import blogsHeaderSlice from "./settings/blogs/blogsHeaderSlice";
import usersSlice from "./users/usersSlice";
import contactsSlice from "./contacts/contactsSlice";
import aboutOtherSlice from "./settings/about/aboutOtherSlice";
import ordersSlice from "./orders/ordersSlice";

export default configureStore({
  reducer: {
    sidebar: sidebarSlice,
    auth: authSlice,
    homeHeader: homeHeaderSlice,
    homeOffer: homeOfferSlice,
    homeAbout: homeAboutSlice,
    homeAdvice: homeAdviceSlice,
    globalConfig: globalConfigSlice,
    globalDiscover: globalDiscoverSlice,
    aboutHeader: aboutHeaderSlice,
    aboutAbout: aboutAboutSlice,
    aboutOther: aboutOtherSlice,
    blogs: blogsSlice,
    books: booksSlice,
    categories: categoriesSlice,
    sliders: slidersSlice,
    booksHeader: booksHeaderSlice,
    blogsHeader: blogsHeaderSlice,
    users: usersSlice,
    contacts: contactsSlice,
    orders: ordersSlice
  },
});