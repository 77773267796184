import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "src/utilities/axios";

let initialState = {
  isLoading: false,
  data: {},
  error: null,
}

export const getAboutAbout = createAsyncThunk(
  "aboutAbout/getAboutAbout",
  async (_, thunkApi) => {
    try {
      const { data } = await axiosInstace.get("/about/about");
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateAboutAbout = createAsyncThunk(
  "aboutAbout/updateAboutAbout",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.put("/about/about", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const aboutAboutSlice = createSlice({
  name: "aboutAbout",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAboutAbout.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAboutAbout.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(getAboutAbout.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.error;
      })

      .addCase(updateAboutAbout.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateAboutAbout.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(updateAboutAbout.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.error;
      })
  }
});

export default aboutAboutSlice.reducer;