import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import {
  Modal, ModalOverlay,
  ModalContent, ModalHeader,
  ModalFooter, ModalBody,
  Button, FormControl,
  FormLabel, Input,
  Textarea,
  Flex, Text,
  Alert,
  AlertIcon,
  Stack,
  useToast,
  Select
} from '@chakra-ui/react';

import * as FiIcons from 'react-icons/fi';

import { createBook } from '../../../../store/books/booksSlice';

import theme from '../../../global/theme';
import { useEffect, useState } from "react";
import { getCategories } from "src/store/categories/categoriesSlice";
import Search from "src/components/shared/search/Search";

const CreateModal = ({ onClose }) => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const books = useSelector(state => state.books);
  const categories = useSelector(state => state.categories);
  const [categoriesPage, setCategoriesPage] = useState(1);

  const dispatch = useDispatch();
  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    dispatch(getCategories({ page: categoriesPage, size: 10, query: "" }))
  }, [dispatch, categoriesPage]);

  watch(["category"]);

  return (
    <Modal isOpen={true} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        <form onSubmit={handleSubmit((values, event) => {
          const formData = new FormData(event.target);
          formData.set("category", values.category?._id);
          dispatch(createBook(formData))
            .unwrap()
            .then(res => {
              toast({ description: "item created" });
              onClose();
            }).catch(e => {
              console.log(e);
            })
        })}>
          <ModalHeader textAlign="center" color="orange" textTransform="uppercase" fontSize={22}>
            {t('general.create')}
          </ModalHeader>
          <ModalBody>

            {books.error && (
              <Alert status="error" variant="left-accent" marginBottom={8}>
                <AlertIcon />
                <Text>{books.error}</Text>
              </Alert>
            )}

            <Stack spacing={6}>
              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.books.category')}
                </FormLabel>
                <Search
                  page={categoriesPage}
                  handleChangePage={(page) => setCategoriesPage(page)}
                  currentElement={getValues("category")}
                  handleSelected={(el) => setValue("category", el)}
                  prop="title_ar"
                  data={categories.data}
                  itemsCount={categories.itemsCount}
                  allowSearch={false}
                />
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.books.title_ar')}
                </FormLabel>
                <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.books.title_ar')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("title_ar", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.title_ar?.message &&
                  <Text color="red.600" marginTop={2}>{errors.title_ar.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.books.title_en')}
                </FormLabel>
                <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.books.title_en')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("title_en", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.title_en?.message &&
                  <Text color="red.600" marginTop={2}>{errors.title_en.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.books.price')}
                </FormLabel>
                <Input type="number" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.books.price')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("price", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.price?.message &&
                  <Text color="red.600" marginTop={2}>{errors.price.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.books.discount')}
                </FormLabel>
                <Input type="number" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.books.discount')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("discount", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.discount?.message &&
                  <Text color="red.600" marginTop={2}>{errors.discount.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.books.image')}
                </FormLabel>
                <Input type="file" pt={1} bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.books.image')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("image")}
                />
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.books.description_ar')}
                </FormLabel>
                <Textarea bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.books.description_ar')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("description_ar")}
                />
                {errors.description_ar?.message &&
                  <Text color="red.600" marginTop={2}>{errors.description_ar.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.books.description_en')}
                </FormLabel>
                <Textarea bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.books.description_en')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("description_en")}
                />
                {errors.description_en?.message &&
                  <Text color="red.600" marginTop={2}>{errors.description_en.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.books.video')}
                </FormLabel>
                <Input type="file" pt={1} bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.books.video')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("video")}
                />
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.books.pages')}
                </FormLabel>
                <Input type="number" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.books.pages')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("pages", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.pages?.message &&
                  <Text color="red.600" marginTop={2}>{errors.pages.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.books.author_ar')}
                </FormLabel>
                <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.books.author_ar')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("author_ar", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.author_ar?.message &&
                  <Text color="red.600" marginTop={2}>{errors.author_ar.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.books.author_en')}
                </FormLabel>
                <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.books.author_en')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("author_en", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.author_en?.message &&
                  <Text color="red.600" marginTop={2}>{errors.author_en.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.books.publisher_ar')}
                </FormLabel>
                <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.books.publisher_ar')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("publisher_ar", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.publisher_ar?.message &&
                  <Text color="red.600" marginTop={2}>{errors.publisher_ar.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.books.publisher_en')}
                </FormLabel>
                <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.books.publisher_en')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("publisher_en", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.publisher_en?.message &&
                  <Text color="red.600" marginTop={2}>{errors.publisher_en.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.books.copyright_registration_number')}
                </FormLabel>
                <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.books.copyright_registration_number')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("copyright_registration_number", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.copyright_registration_number?.message &&
                  <Text color="red.600" marginTop={2}>{errors.copyright_registration_number.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.books.language')}
                </FormLabel>
                <Select type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  {...register("lang", {
                    required: `${t('validation.required')}`
                  })}
                >
                  <option value="ar">arabic</option>
                  <option value="en">english</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.books.reading_age')}
                </FormLabel>
                <Input type="number" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.books.reading_age')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("reading_age", {
                    required: `${t('validation.required')}`
                  })}
                />
                {errors.reading_age?.message &&
                  <Text color="red.600" marginTop={2}>{errors.reading_age.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.books.terms_of_sale_ar')}
                </FormLabel>
                <Textarea bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.books.terms_of_sale_ar')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("terms_of_sale_ar")}
                />
                {errors.terms_of_sale_ar?.message &&
                  <Text color="red.600" marginTop={2}>{errors.terms_of_sale_ar.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.books.terms_of_sale_en')}
                </FormLabel>
                <Textarea bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.books.terms_of_sale_en')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("terms_of_sale_en")}
                />
                {errors.terms_of_sale_en?.message &&
                  <Text color="red.600" marginTop={2}>{errors.terms_of_sale_en.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.books.intro_ar')}
                </FormLabel>
                <Textarea bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.books.intro_ar')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("intro_ar")}
                />
                {errors.intro_ar?.message &&
                  <Text color="red.600" marginTop={2}>{errors.intro_ar.message}</Text>}
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                  {t('pages.books.intro_en')}
                </FormLabel>
                <Textarea bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                  placeholder={t('pages.books.intro_en')} _placeholder={{ textTransform: 'capitalize' }}
                  {...register("intro_en")}
                />
                {errors.intro_en?.message &&
                  <Text color="red.600" marginTop={2}>{errors.intro_en.message}</Text>}
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white" bg="green" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                isLoading={books.isLoading}
                _hover={{ background: 'green' }}
              >
                {t('general.save')}
              </Button>
              <Button
                type="button"
                onClick={onClose}
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white" bg="red.600" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                marginInlineStart={4}
                isLoading={books.isLoading}
                _hover={{ background: 'red.600' }}
              >
                {t('general.close')}
              </Button>
            </Flex>
          </ModalFooter>
        </form >
      </ModalContent >
    </Modal >
  )
}

export default CreateModal