import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Flex, Text, InputGroup, Input, InputRightElement, Alert, AlertIcon } from '@chakra-ui/react';

import * as FiIcons from 'react-icons/fi';

import Pagination from 'src/components/shared/pagination/Pagination';
import Breadcrumbs from 'src/components/shared/breadcrumbs/Breadcrumbs';
import ContactsTable from './Table';

import { getContacts } from 'src/store/contacts/contactsSlice';

import { Wrapper } from './Styles';

import theme from '../../global/theme';
import DeleteModal from "./actions/DeleteModal";

const Contacts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contacts = useSelector(state => state.contacts);

  const [showDeleteModal, setShowDeleteModal] = useState(null);


  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");

  useEffect(() => {
    dispatch(getContacts({ page, query }));
  }, [dispatch, page, query]);


  return (
    <Wrapper>
      <Breadcrumbs
        currentPage={t('pages.contacts.contacts')}
        pages={[{ name: `${t('pages.dashboard.dashboard')}`, path: '/' }]}
      />

      {contacts.error && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          <Text>{contacts.error}</Text>
        </Alert>
      )}

      <Flex marginBottom={8} justifyContent="flex-end">
        <InputGroup width="auto">
          <Input auth="auto" type="text" placeholder={t('general.search')}
            bg={theme.light} boxShadow={theme.shadow} color={theme.dark} border="none"
            onChange={(e) => setQuery(e.currentTarget.value)}
          />
          <InputRightElement color={theme.light} children={<FiIcons.FiSearch />} />
        </InputGroup>
      </Flex>

      {contacts.itemsCount > 0 ? (
        <ContactsTable
          data={contacts.data}
          page={page}
          handleDelete={(el) => setShowDeleteModal(el)}
        />
      ) : (
        <Flex textAlign="center" bg={theme.light} boxShadow={theme.shadow} height={200} alignItems="center"
          justifyContent="center" borderRadius={16}>
          <Text fontSize={18} textTransform="capitalize" color="gray.300" fontWeight="bold">
            {t('pages.contacts.no_contacts')}
          </Text>
        </Flex>
      )}

      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          itemsCount={contacts.itemsCount ?? 0}
          onChange={(page) => setPage(page)}
        />
      </Flex>

      {showDeleteModal && (
        <DeleteModal
          onClose={() => setShowDeleteModal(null)}
          data={showDeleteModal}
        />
      )}
    </Wrapper>
  )
}

export default Contacts