import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "src/utilities/axios";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  itemsCount: 0,
  error: null,
}

export const getSliders = createAsyncThunk(
  "sliders/getSliders",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query } = args;
      const { data } = await axiosInstace.get(`/sliders?page=${page}&size=${size}&query=${query}`,);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createSlider = createAsyncThunk(
  "sliders/createSlider",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.post("sliders", args);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const updateSlider = createAsyncThunk(
  "sliders/updateSlider",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.put(`/sliders/${args._id}`, args.values);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const deleteSlider = createAsyncThunk(
  "sliders/deleteSlider",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.delete(`/sliders/${args._id}`);
      return thunkApi.fulfillWithValue(data);
    }
    catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
)

const slidersSlice = createSlice({
  name: "sliders",
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getSliders.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSliders.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data = payload.data;
        state.pages = payload.pages;
        state.itemsCount = payload.itemsCount;
      })
      .addCase(getSliders.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.error;
      })

      .addCase(createSlider.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createSlider.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data.push(payload.data);
        state.itemsCount++;
      })
      .addCase(createSlider.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.error;
      })

      .addCase(updateSlider.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateSlider.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.isLoading = false;
        state.error = null;
        const indexAt = state.data?.findIndex(el => el._id === payload.data._id);
        state.data[indexAt] = payload.data;
      })
      .addCase(updateSlider.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.error;
      })

      .addCase(deleteSlider.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteSlider.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.data = state.data.filter(el => el._id !== payload.data._id);
      })
      .addCase(deleteSlider.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.error;
      })
  }
});

export default slidersSlice.reducer;