import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "src/utilities/axios";

let initialState = {
  isLoading: false,
  data: {},
  error: null,
}

export const getAboutHeader = createAsyncThunk(
  "aboutHeader/getAboutHeader",
  async (_, thunkApi) => {
    try {
      const { data } = await axiosInstace.get("/about/header");
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateAboutHeader = createAsyncThunk(
  "aboutHeader/updateAboutHeader",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.put("/about/header", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const aboutHeaderSlice = createSlice({
  name: "aboutHeader",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAboutHeader.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAboutHeader.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(getAboutHeader.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.error;
      })

      .addCase(updateAboutHeader.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateAboutHeader.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(updateAboutHeader.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.error;
      })
  }
});

export default aboutHeaderSlice.reducer;