import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "src/utilities/axios";

let initialState = {
  isLoading: false,
  data: {},
  error: null,
}

export const getAboutOther = createAsyncThunk(
  "aboutOther/getAboutOther",
  async (_, thunkApi) => {
    try {
      const { data } = await axiosInstace.get("/about/other");
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateAboutOther = createAsyncThunk(
  "aboutOther/updateAboutOther",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.put("/about/other", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const aboutOtherSlice = createSlice({
  name: "aboutOther",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAboutOther.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAboutOther.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(getAboutOther.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.error;
      })

      .addCase(updateAboutOther.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateAboutOther.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(updateAboutOther.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.error;
      })
  }
});

export default aboutOtherSlice.reducer;