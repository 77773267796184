import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Editor } from 'primereact/editor';
import pdfToText from 'react-pdftotext'


import {
  Modal, ModalOverlay,
  ModalContent, ModalHeader,
  ModalFooter, ModalBody,
  Button, FormControl,
  FormLabel, Input,
  Flex, Text,
  Alert,
  AlertIcon,
  Stack,
  useToast,
} from '@chakra-ui/react';

import * as FiIcons from 'react-icons/fi';

import { updateBookDetails } from '../../../../store/books/booksSlice';

import theme from '../../../global/theme';

const ChaptersModal = ({ data, onClose }) => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const books = useSelector(state => state.books);

  const dispatch = useDispatch();
  const {
    register,
    control,
    getValues,
    setValue,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: data
  });

  watch(["chapters"]);

  const { fields: chapters, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: "chapters", // unique name for your Field Array
  });

  return (
    <Modal isOpen={true} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        <form onSubmit={handleSubmit((values, event) => {
          dispatch(updateBookDetails({ _id: data._id, values }))
            .unwrap()
            .then(res => {
              toast({ description: "item updated" });
              onClose();
            }).catch(e => {
              console.log(e);
            })
        })}>
          <ModalHeader textAlign="center" color="orange" textTransform="uppercase" fontSize={22}>
            {t('general.update')}
          </ModalHeader>
          <ModalBody>

            {books.error && (
              <Alert status="error" variant="left-accent" marginBottom={8}>
                <AlertIcon />
                <Text>{books.error}</Text>
              </Alert>
            )}

            <Button
              width="100%" background={theme.mainColor} color="white" mb={6}
              textTransform="capitalize"
              onClick={() => append({ title_ar: "", title_en: "", description_ar: "", description_en: "" })}
            >
              add chapter
            </Button>

            {chapters.map((chapter, idx) => (
              <Stack
                spacing={6} p={6} mb={6}
                border="1px solid #eee"
              >
                <FormControl>
                  <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                    {t('pages.books.title_ar')}
                  </FormLabel>
                  <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                    placeholder={t('pages.books.title_ar')} _placeholder={{ textTransform: 'capitalize' }}
                    {...register(`chapters.${idx}.title_ar`, {
                      required: `${t('validation.required')}`
                    })}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                    {t('pages.books.title_en')}
                  </FormLabel>
                  <Input type="text" bg={theme.bg} color={theme.dark} border="none" borderRadius={4}
                    placeholder={t('pages.books.title_en')} _placeholder={{ textTransform: 'capitalize' }}
                    {...register(`chapters.${idx}.title_en`, {
                      required: `${t('validation.required')}`
                    })}
                  />
                  {errors.title_en?.message &&
                    <Text color="red.600" marginTop={2}>{errors.title_en.message}</Text>}
                </FormControl>

                <FormControl>
                  <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                    {t('pages.books.description_ar')}
                  </FormLabel>
                  <Editor
                    value={getValues(`chapters.${idx}.description_ar`)}
                    onTextChange={(e) => setValue(`chapters.${idx}.description_ar`, e.htmlValue)}
                    style={{ backgroundColor: "#fff", height: '120px' }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                    read from pdf
                  </FormLabel>
                  <Input
                    type="file" placeholder="read from pdf" color="black" accept="application/pdf"
                    bg="white" pt={1}
                    onChange={async (e) => {
                      const file = e.target.files[0]
                      pdfToText(file)
                        .then(text => {
                          console.log(text);
                          setValue(`chapters.${idx}.description_ar`, text);
                        })
                        .catch(error => console.error("Failed to extract text from pdf"))
                    }}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                    {t('pages.books.description_en')}
                  </FormLabel>
                  <Editor
                    value={getValues(`chapters.${idx}.description_en`)}
                    onTextChange={(e) => setValue(`chapters.${idx}.description_en`, e.htmlValue)}
                    style={{ backgroundColor: "#fff", height: '120px' }}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
                    read from pdf
                  </FormLabel>
                  <Input
                    type="file" placeholder="read from pdf" color="black" accept="application/pdf"
                    bg="white" pt={1}
                    onChange={async (e) => {
                      const file = e.target.files[0]
                      pdfToText(file)
                        .then(text => {
                          console.log(text);
                          setValue(`chapters.${idx}.description_en`, text);
                        })
                        .catch(error => console.error("Failed to extract text from pdf"))
                    }}
                  />
                </FormControl>

                <Button
                  width="100%" background={theme.error} color="white"
                  textTransform="capitalize"
                  onClick={() => remove(idx)}
                >
                  remove chapter
                </Button>
              </Stack>
            ))}
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white" bg="green" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                isLoading={books.isLoading}
                _hover={{ background: 'green' }}
              >
                {t('general.save')}
              </Button>
              <Button
                type="button"
                onClick={onClose}
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white" bg="red.600" paddingInline={4}
                paddingBlock={2} height="auto" textTransform="capitalize"
                marginInlineStart={4}
                isLoading={books.isLoading}
                _hover={{ background: 'red.600' }}
              >
                {t('general.close')}
              </Button>
            </Flex>
          </ModalFooter>
        </form >
      </ModalContent >
    </Modal >
  )
}

export default ChaptersModal