import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstace from "src/utilities/axios";

let initialState = {
  isLoading: false,
  data: {},
  error: null,
}

export const getBooksHeader = createAsyncThunk(
  "booksHeader/getBooksHeader",
  async (_, thunkApi) => {
    try {
      const { data } = await axiosInstace.get("/books/header");
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

export const updateBooksHeader = createAsyncThunk(
  "booksHeader/updateBooksHeader",
  async (args, thunkApi) => {
    try {
      const { data } = await axiosInstace.put("/books/header", args);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(errors.response.data);
    }
  }
);

const booksHeaderSlice = createSlice({
  name: "booksHeader",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getBooksHeader.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getBooksHeader.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(getBooksHeader.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.error;
      })

      .addCase(updateBooksHeader.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateBooksHeader.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.data || {};
        state.error = null;
      })
      .addCase(updateBooksHeader.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload.error;
      })
  }
});

export default booksHeaderSlice.reducer;