import { useTranslation } from 'react-i18next';
import Table from '../../shared/table/Table';

const GTable = ({ data, page }) => {
  const { t } = useTranslation();
  return (
    <Table>
      <thead>
        <tr>
          <th>#</th>
          <th>{t('pages.orders.book')}</th>
          <th>{t('pages.orders.name')}</th>
          <th>{t('pages.orders.email')}</th>
          <th>{t('pages.orders.phone')}</th>
          <th>{t('pages.orders.ref_number')}</th>
          <th>{t('pages.orders.amount')}</th>
          <th>payment method</th>
          <th>password</th>
          <th>{t('pages.orders.status')}</th>
        </tr>
      </thead>
      <tbody>
        {data.map((el, index) => (
          <tr key={el._id}>
            <td>{((page - 1) * 10) + (index + 1)}</td>
            <td>{el.book?.title_ar}</td>
            <td>{el.name}</td>
            <td>{el.email}</td>
            <td>{el.phone}</td>
            <td>{el.ref_number}</td>
            <td>{el.amount}</td>
            <td>{el.payment_method}</td>
            <td>{el.password}</td>
            <td>{el.status}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default GTable