import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Image } from '@chakra-ui/react';
import * as FiIcons from 'react-icons/fi';

import Table from '../../shared/table/Table';
import { baseUrl } from "src/utilities/axios";

const GTable = ({ data, page, handleUpdate, handleDelete }) => {
  const { t } = useTranslation();
  return (
    <Table>
      <thead>
        <tr>
          <th>#</th>
          <th>{t('pages.sliders.image')}</th>
          <th>{t('general.action')}</th>
        </tr>
      </thead>
      <tbody>
        {data.map((el, index) => (
          <tr key={el._id}>
            <td>{((page - 1) * 10) + (index + 1)}</td>
            <td>
              <Image
                src={`${baseUrl}/sliders/${el.image}`}
                height="100px"
                width="100px"
                margin="auto"
              />
            </td>
            <td>
              <Button
                bg="transparent" color="red.600" size="xs"
                onClick={() => handleDelete(el)}
              >
                <FiIcons.FiDelete size={20} />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default GTable